<template>
  <div>
    <button-all-header
      :contentBtnAdd="'Thêm nội dung'"
      :arrayExcel="[]"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      @clickDelete="deleteItems"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />

    <b-table-simple
      hover
      small
      caption-top
      responsive
      class="table-simple"
    >
      <colgroup><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col></colgroup>
      <b-thead>
        <b-tr>
          <b-th>
            <b-form-checkbox
              v-model="checkAll"
              :indeterminate="showBtnMultiDelete"
              @input="selectAll(dataList)"
            />
          </b-th>
          <b-th>
            STT
          </b-th>
          <b-th colspan="2">
            NỘI DUNG HUẤN LUYỆN
          </b-th>
          <b-th></b-th>
          <b-th colspan="2">
            THỜI GIAN HUẤN LUYỆN (GIỜ)
          </b-th>
          <b-th></b-th>
          <b-th>
            CHỨC NĂNG
          </b-th>
        </b-tr>
        <b-tr>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th>TỔNG SỐ</b-th>
          <b-th></b-th>
          <b-th>TRONG ĐÓ</b-th>
          <b-th></b-th>
          <b-th></b-th>
        </b-tr>
        <b-tr>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th class="border-bottom ">
            LÝ THUYẾT
          </b-th>
          <b-th class="border-bottom ">
            THỰC HÀNH
          </b-th>
          <b-th class="border-bottom ">
            KIỂM TRA
          </b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(item, index) in dataList"
          :key="index"
        >
          <b-td class="pb-25">
            <b-form-checkbox
              v-model="item.isChecked"
              @change="selectRowTable(item)"
            />
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.index }}
          </b-td>
          <b-td></b-td>
          <b-td class="pb-1 pt-1">
            {{ item.content }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.total }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.theory }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.practice }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.exam }}
          </b-td>
          <b-td class="pb-1 pt-1">
            <feather-icon
              icon="EditIcon"
              size="18"
              class="text-body ml-2"
              @click="showModalEdit(item.id)"
            />
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body ml-2"
              @click="deleteItem(item.id)"
            />
          </b-td>
        </b-tr>
      </b-tbody>

    </b-table-simple>
    <div
      v-if="dataList.length === 0"
      class="empty-table d-block"
    >
      <div class="w-100 d-flex align-items-center justify-content-center">
        <b-img
          class="img-empty-table"
          fluid
          :src="emptyImg"
          alt="empty table"
        />
      </div>
    </div>
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="handlePageClick"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <!-- Tạo đối tượng kiểm định -->
    <modal-create-object
      :id="modalCreateObject"
      :type="modalType"
      :dataDetail="dataDetail"
      @handleCreateObject="handleCreate($event)"
    />
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BDropdown,
  BDropdownItem,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BThead,
  BFormCheckbox,
  BImg,
} from 'bootstrap-vue'

import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalCreateObject from './modal-create/ModalCreateObject.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    BDropdown,
    BDropdownItem,
    ButtonAllHeader,
    ConfirmModal,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BThead,
    BFormCheckbox,
    ModalCreateObject,
    BImg,
  },
  filters: {
    formatDateToDDMM,
  },
  props: {
    conditionTrainingId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formatFullName,
      currentPage: 0,
      urlQuery: {
        selfDeclareTrainingId: this.conditionTrainingId,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal-dele',
      modalCreateObject: 'confirm-object-condition',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      dataListObject: [],
      totalRecord: 0,
      dataList: [],
      checkId: [],
      idDetail: '',
      dataDetail: {},
      modalType: '',
      checkAll: false,
      // eslint-disable-next-line global-require
      emptyImg: require('@/assets/images/svg/No-data.gif'),

    }
  },

  created() {
    this.fetchListObject(this.urlQuery)
  },
  methods: {

    async fetchListObject(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_PROGRAM, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.dataList.forEach(e => { e.isChecked = false })
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },
    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchListObject(this.urlQuery)
    },

    // tìm kiếm lộ trình đào tạo
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchListObject(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchListObject(this.urlQuery)
      }
    },

    async handleCreate(param) {
      if (this.modalType === 'add') {
        const payload = {
          ...param,
          selfDeclareTraining: this.conditionTrainingId,
          theory: parseInt(param.theory, 10),
          practice: parseInt(param.practice, 10),
          exam: parseInt(param.exam, 10),
        }
        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.CREATE_PROGRAM, payload).then(response => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalCreateObject)
          this.fetchListObject(this.urlQuery)
        }).catch(e => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(e.response.data.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      } else {
        const payload = {
          ...param,
          id: this.idDetail,
          selfDeclareTraining: this.conditionTrainingId,
          theory: parseInt(param.theory, 10),
          practice: parseInt(param.practice, 10),
          exam: parseInt(param.exam, 10),
        }
        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.EDIT_PROGRAM, payload).then(response => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Sửa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalCreateObject)
          this.fetchListObject(this.urlQuery)
        }).catch(e => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(e.response.data.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }
    },

    // mở modal tạo mới đối tượng kiểm định
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalCreateObject)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      if (val.isChecked) {
        this.deleteIds.push(val.id)
      } else {
        this.deleteIds = this.deleteIds.filter(item => item !== val.id)
      }
      this.checkLength()
    },

    checkLength() {
      if (this.deleteIds.length > 0) {
        this.showBtnMultiDelete = true
      } else {
        this.showBtnMultiDelete = false
        this.checkAll = false
      }
    },

    selectAll() {
      if (this.checkAll) {
        this.deleteIds = this.dataList.map(e => e.id)
        this.dataList.forEach(e => { e.isChecked = true })
      } else {
        this.dataList.forEach(e => { e.isChecked = false })
      }
      this.checkLength()
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = [id]
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      const payload = {
        ids: this.deleteIds,
        selfDeclareTrainingId: this.conditionTrainingId,
      }
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.DELETE_PROGRAM, payload)
      if (res.status === 200) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListObject(this.urlQuery)
        this.checkAll = false
        this.showBtnMultiDelete = false
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thất bại', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    async showModalEdit(id) {
      this.modalType = 'Edit'
      this.idDetail = id
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_PROGRAM}${id}`)
      this.dataDetail = data
      this.$bvModal.show(this.modalCreateObject)
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.text-name {
  color: #227ff4;
}
.table-simple {
  .table {
    thead {
      tr {
        th {
          background-color: rgba(34, 127, 244, 0.12) !important;
        }
      }
    }
  }
}
</style>
<style lang="css" scoped>
.empty-table {
 display: flex;
 justify-content: center;
 align-items: center;
}
</style>
